import { Column, Row } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Card from "../../components/Card/Card";
import http from "../../http-common";
import { PieChart, StackedBarChart } from "@carbon/charts-react";
import {
  AppSwitcher20,
  Home16,
  UserAvatarFilledAlt32,
  Logout16,
  Finance32,
  Percentage32,
  PiggyBank32,
  UserMultiple16,
  CurrencyDollar32,
  Dashboard32,
  User32,
} from "@carbon/icons-react";

const pieChartOptions = {
  title: "",
  resizable: true,
  legend: {
    alignment: "center",
  },
  pie: {
    alignment: "center",
  },
  height: "200px",
};


const pieChartOptions1 = {
  title: "",
  resizable: true,
  legend: {
    alignment: "center",
  },
  pie: {
    alignment: "center",
  },
  height: "400px",
};


const barChartOptions = {
  title: "",
  axes: {
    left: { mapsTo: "value" },
    bottom: { mapsTo: "group", scaleType: "labels" },
  },
  height: "400px",
};

const options = {
  title: "",
  axes: {
    left: {
      mapsTo: "value",
      stacked: true,
    },
    bottom: {
      mapsTo: "key",
      scaleType: "labels",
    },
  },
  height: "400px",
};
const DashboardPage = () => {
  const [stats, setStats] = useState({});
  const [byGender, setByGender] = useState([]);
  const [byExistingBusiness, setByExistingBusiness] = useState([]);
  const [byProvince, setByProvince] = useState([]);
  const [bySubmitedCandidature, setBySubmitedCandidature] = useState([]);
  const [byProvinceSubmitedCandidature, setByProvinceSubmitedCandidature] = useState([]);


  // const getStats = async () => {
  //   try {
  //     const response = await http.get("/applications/stats");
  //     setStats(response.data);
  //     setByGender(response.data.byGender);
  //     setByProvince(response.data.byProvince);
  //     setByExistingBusiness(response.data.byExistingBusiness);
  //     setBySubmitedCandidature(response.data.bySubmitedCandidature);
  //     setByProvinceSubmitedCandidature(response.data.byProvinceSubmitedCandidature);
  //   } catch (error) {
  //     toast.error("Erro ao carregar dados estatísticos");
  //   }
  // };

  useEffect(() => {
    //getStats();
  }, []);

  return (
    <>
      <Card title="Dashboard">
      <div className="dashboard">
        {/* <h3> Dashboard</h3> */}
        <Row>
          <Column xsm={12} sm={12} lg={4} xlg={4}>
            <Card title="Total de Utilizadores">
              <h2>12 <User32 /></h2>
              
              {/* <h1>{stats.total}</h1> */}
            </Card>
          </Column>
          <Column xsm={12} sm={12} lg={4} xlg={4}>
            <Card title="Total de Bancos">
              <h2>5 <Finance32 /></h2>
              {/* <PieChart data={byGender} options={pieChartOptions}></PieChart> */}
            </Card>
          </Column>
          <Column xsm={12} sm={12} lg={4} xlg={4}>
            <Card title="TAXA DIÁRIA: Registos">
            <h2>25 <Percentage32 /></h2>
              {/* <PieChart
                data={byExistingBusiness}
                options={pieChartOptions}
              ></PieChart> */}
            </Card>
          </Column>
        </Row>
        <Row>
          <Column className="mt-2">
            <Card title="Numero total de Simulador de Empréstimo">
             <h2>30 <PiggyBank32 /></h2>
              {/* <StackedBarChart
                data={byProvince}
                options={options}
              ></StackedBarChart> */}
            </Card>
          </Column>
        </Row>
       {/* <Row>
       <Column className="mt-2" >
            <Card title="Candidaturas por Submeter e Submetidas">
              <PieChart
                data={bySubmitedCandidature}
                options={pieChartOptions1}
              ></PieChart>
            </Card>
          </Column>
       </Row>
       <Row>
          <Column className="mt-2">
            <Card title="Candidaturas por Submeter e Submetidas por Província">
              <p className="container">12</p>
              <StackedBarChart
                data={byProvinceSubmitedCandidature}
                options={options}
              ></StackedBarChart>
            </Card>
          </Column>
        </Row> */}
      </div>
      </Card>
    </>
  );
};

export default DashboardPage;
