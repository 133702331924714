import { Column, Row } from "carbon-components-react";
import React from "react";
import ologa from "../../assets/images/ologa-2.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer">
      <div className="bx--grid">
        <Column sm={12} md={12} lg={10} xlg={10} style={{ margin:"auto"}}>
          <Row>
            <Column>
              <p>
                {currentYear} © Desenvolvido por |{" "}
                <a
                  href="http://www.ologa.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#ddd" }}
                >
                  Ologa – Sistemas Informáticos
                </a>
                <span style={{marginLeft: "20px"}}>
                    <a href="https://ologa.com/">
                      <img
                        src={ologa}
                        alt="Ologa"
                        width={80}
                        height={30}
                        style={{ marginBottom: "-10px" }}
                      />
                    </a>
                </span>
                      
              </p>
            </Column>
          </Row>
        </Column>
      </div>
    </div>
  );
};
export default Footer;
