import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../http-common";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isCompetitor, setIsCompetitor] = useState(false);
  const [isPassChanged, setIsPassChanged] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const login = async (username, password) => {
    try {
      const response = await http.post(
        "/auth/login?username=" + username + "&password=" + password
      );
      const principal = {
        id: response.data.id,
        username: response.data.username,
        firstName: response.data.firstname,
        lastName: response.data.lastname,
        roleCode: response.data.roles[0].role,
        accessToken: response.data.token,
      };

      setUser(principal);
      checkIsCompetitor(response.data.roleCode);
      localStorage.setItem("principal", JSON.stringify(principal));
      const userData = checkIsCompetitor(response.data.roles[0].role)
        ? await http.get("/clients/users/" + principal.username)
        : "";

      const userLoggedIn = await http.get(
        "/users/" + username + "/" + password
      );

      if (checkIsPassChanged(response.data.passwordChanged)) {
        navigate("/passwordUpdate/" + userLoggedIn.data.id);
      } else if (checkIsCompetitor(response.data.roles[0].role)) {
        navigate("/simulador/" + userData.data.id);
        //logout();
      } else {
        navigate("/admin/dashboard");
      }
    } catch (error) {
      toast.error("Utilizador ou senha inválidos");
    }
  };

  const loginOfLoan = async (username, password) => {
    try {
      const response = await http.post(
        "/auth/login?username=" + username + "&password=" + password
      );
      const principal = {
        username: response.data.username,
        firstName: response.data.firstname,
        lastName: response.data.lastname,
        roleCode: response.data.roles[0].role,
        accessToken: response.data.token,
      };
      setUser(principal);
      checkIsCompetitor(response.data.roleCode);

      localStorage.setItem("principal", JSON.stringify(principal));
      // navigate(
      //   checkIsCompetitor(principal.roleCode)
      //     ? "/simulador/" + response.data.clientId
      //     : "/admin/dashboard"
      // );
    } catch (error) {
      toast.error("Ocorreu um erro na autenticação");
    }
  };

  const checkIsCompetitor = (roleCode) => {
    let role = "ROLE_SELLER";
    let result = roleCode === role;
    setIsCompetitor(result);
    return result;
  };

  // const checkIsCompetitor1 = (roleCode, bankName) => {
  //   let role = "EXTERNAL_USER";
  //   let result = roleCode === role;
  //   setIsCompetitor(result);
  //   return result;

  // };

  const checkIsPassChanged = (status) => {
    let result = status === false;
    setIsPassChanged(result);
    return result;
  };

  const logout = () => {
    setUser(null);
    setIsCompetitor(false);
    localStorage.removeItem("principal");
    navigate("/");
  };

  useEffect(() => {
    const loggedUser = localStorage.getItem("principal");
    if (loggedUser) {
      const parsed = JSON.parse(loggedUser);
      setUser(parsed);
      checkIsCompetitor(parsed.roleCode);
    }
    setLoading(false);
  }, []);
  return (
    <AuthContext.Provider
      value={{
        authenticated: !!user,
        user,
        isCompetitor,
        login,
        logout,
        loginOfLoan,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
