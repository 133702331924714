// This should come from the database

const menu = (role) => {
  const menuItems = [
    {
      id: 1,
      label: "Candidaturas",
      icon: "UserMultiple16",
      roles: ["ROLE_SELLER", "202", "203", "204"],
      items: [
        {
          id: 1.1,
          label: "Registar",
          link: "/admin/registar-candidatura",
          roles: ["202", "203"],
        },
        {
          id: 1.2,
          label: "Consultar",
          link: "/admin/candidaturas",
          roles: ["202", "203"],
        },
        {
          id: 1.3,
          label: "Minha Candidatura",
          link: "/admin/inicio",
          roles: ["ROLE_SELLER"],
        },
      ],
    },

    {
      id: 2,
      label: "Bancos",
      icon: "Fade16",
      roles: ["ROLE_APPLICANT"],
      items: [
        {
          id: 2.1,
          label: "Registar",
          link: "/admin/registar-banco",
          roles: ["ROLE_APPLICANT"],
        },
        {
          id: 2.2,
          label: "Consultar",
          link: "/admin/bancos",
          roles: ["ROLE_APPLICANT"],
        },
      ],
    },


    {
      id: 3,
      label: "Taxas Bancárias",
      icon: "UserMultiple16",
      roles: ["ROLE_APPLICANT"],
      items: [
        {
          id: 3.1,
          label: "Registar",
          link: "/admin/registar-taxa",
          roles: ["ROLE_APPLICANT"],
        },
        {
          id: 3.2,
          label: "Consultar",
          link: "/admin/taxas-bancarias",
          roles: ["ROLE_APPLICANT"],
        },
      ],
    },
    {
      id: 4,
      label: "Utilizadores",
      icon: "UserMultiple16",
      roles: ["ROLE_APPLICANT"],
      items: [
        {
          id: 4.1,
          label: "Registar",
          link: "/admin/registar-utilizador",
          roles: ["ROLE_APPLICANT"],
        },
        {
          id: 4.2,
          label: "Consultar",
          link: "/admin/utilizadores",
          roles: ["ROLE_APPLICANT"],
        },
      ],
    },
  ];

  const userMenu = menuItems.filter((m) => m.roles.includes(role));
  userMenu.forEach((menu) => {
    menu.items = menu.items.filter((mi) => mi.roles.includes(role));
  });

  return userMenu;
};

export default menu;
