import "react-toastify/dist/ReactToastify.css";
import "./app.scss";
import { Routes, Route } from "react-router-dom";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import AdminLayout from "./layouts/AdminLayout";
import PublicLayout from "./layouts/PublicLayout";
import HomePage from "./pages/Home/HomePage";
import NotFoudPage from "./pages/404/NotFoudPage";
import CompetitorDashboardPage from "./pages/Dashboard/CompetitorDashboardPage";
import { AuthProvider } from "./contexts/AuthContext";
import React, { Suspense, lazy } from "react";

const UsersPage = lazy(() => import("./pages/User/UsersPage"));
const CreateUserPage = lazy(() => import("./pages/User/CreateUserPage"));
const UserProfilePage = lazy(() => import("./pages/User/UserProfilePage"));
const UpdateUserPage = lazy(() => import("./pages/User/UpdateUserPage"));
const UpdatePasswordPage = lazy(() =>
  import("./pages/User/UpdatePasswordPage")
);

const AddBankPage = lazy(() => import("./pages/Bank/AddBankPage"));
const ListBankPage = lazy(() => import("./pages/Bank/ListBankPage"));
const UpdateBankPage = lazy(() => import("./pages/Bank/UpdateBankPage"));

const SimulationReport = lazy(() => import("./pages/Report/SimulationReport"));
const LoanRequestList = lazy(() => import("./pages/Loan/LoanRequestList"));

const AddBankFeePage = lazy(() => import("./pages/BankFee/AddBankFeePage"));
const UpdateBankFeePage = lazy(() =>
  import("./pages/BankFee/UpdateBankFeePage")
);
const ListBankFeePage = lazy(() => import("./pages/BankFee/ListBankFeePage"));

const SignupPage = lazy(() => import("./pages/User/SignupPage"));
const ContactForm = lazy(() => import("./pages/Contact/ContactForm"));
const CommercialBanks = lazy(() =>
  import("./pages/StandardizedConditions/CommercialBanks")
);
const Microfinance = lazy(() =>
  import("./pages/StandardizedConditions/Microfinance")
);
const AddLoanPage = lazy(() => import("./pages/Loan/AddLoanPage"));
const AddLoanRequestPage = lazy(() =>
  import("./pages/Loan/AddLoanRequestPage")
);
const UpdateLoanRequestPage = lazy(() =>
  import("./pages/Loan/UpdateLoanRequestPage")
);
const PasswordRecoveryPage = lazy(() =>
  import("./pages/User/PasswordRecoveryPage")
);

function App() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<PublicLayout />}>
              <Route path="/" element={<HomePage />}></Route>
              <Route path="login" element={<HomePage />} />
              <Route path="regista-te" element={<SignupPage />} />
              <Route path="contactos" element={<ContactForm />} />
              <Route
                path="condicoes-padronizadas/bancos-comerciais"
                element={<CommercialBanks />}
              />
              <Route
                path="condicoes-padronizadas/microfinancas"
                element={<Microfinance />}
              />
              <Route path="simulador/:id" element={<AddLoanPage />} />
              <Route path="loan-form/:id" element={<AddLoanRequestPage />} />
              <Route path="loan-page/:id" element={<LoanRequestList />} />
              <Route path="request-page/:id" element={<LoanRequestList />} />

              <Route
                path="loan-update-page/:id"
                element={<UpdateLoanRequestPage />}
              />
              <Route
                path="passwordUpdate/:id"
                element={<UpdatePasswordPage />}
              />
              <Route
                path="passwordRecovery"
                element={<PasswordRecoveryPage />}
              />
            </Route>
            <Route path="/admin/*" element={<AdminLayout />}>
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="inicio" element={<CompetitorDashboardPage />} />
              <Route path="registar-banco" element={<AddBankPage />} />
              <Route path="actualizar-banco/:id" element={<UpdateBankPage />} />
              <Route path="bancos" element={<ListBankPage />} />
              <Route path="relatorio" element={<SimulationReport />} />
              <Route path="registar-taxa" element={<AddBankFeePage />} />
              <Route
                path="actualizar-taxa/:id"
                element={<UpdateBankFeePage />}
              />
              <Route path="taxas-bancarias" element={<ListBankFeePage />} />
              <Route path="utilizadores" element={<UsersPage />} />
              <Route path="registar-utilizador" element={<CreateUserPage />} />
              <Route
                path="actualizar-utilizador/:id"
                element={<UpdateUserPage />}
              />
              <Route path="minha-conta" element={<UserProfilePage />} />
            </Route>
            <Route path="*" element={<NotFoudPage />} />
          </Routes>
        </AuthProvider>
      </Suspense>
    </>
  );
}

export default App;
