import {
  Button,
  Form,
  TextInput,
  PasswordInput,
  Row,
  Column,
} from "carbon-components-react";
import {
  Credentials32,
  FaceDissatisfied32,
  Help32,
  ArrowRight24,
} from "@carbon/icons-react";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AuthContext } from "../../contexts/AuthContext";
import ClipLoader from "react-spinners/ClipLoader";

const requiredMessage = "Campo obrigatório";

const loginSchema = yup.object({
  username: yup.string().required(requiredMessage),
  password: yup.string().required(requiredMessage),
});

const LoginForm = () => {
  const { authenticated, login } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false); // adicionando o state loading
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema) });
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setLoading(true);
    await login(data.username, data.password);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Login</h3>
      </div>
      <div className="card-body">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            {...register("username")}
            id="username"
            labelText="NUIT"
            placeholder=""
            maxLength={9}
          />
          <p className="field-error">{errors.username?.message}</p>
          <PasswordInput
            id="password"
            {...register("password")}
            labelText="Senha"
            placeholder=""
          />
          <p className="password-field-message">Introduza a senha enviada por SMS</p>
          <p className="field-error">{errors.password?.message}</p>

          <p style={{ color: "#000", textAlign: "center" }} className="mt-1">
            <Link to={"../passwordRecovery"}>Esqueceu sua senha?</Link>
          </p>
          {!isLoading && (
            <Button
              type="submit"
              renderIcon={ArrowRight24}
              className="mt-2 btn-fluid"
            >
              Login
            </Button>
          )}
          {isLoading && (
            <Button
              type="submit"
              renderIcon={ArrowRight24}
              className="mt-2 btn-fluid"
            >
              <ClipLoader
                isLoading={isLoading}
                color={"#fff"}
                size={20}
                padding={"50px"}
              />
              {/* <p>loading.......</p> */}
            </Button>
          )}
          <p style={{ color: "#000" }} className="mt-1">
            Ainda não tens conta?{" "}
            <Link to={"../regista-te"}>Regista-te já</Link>
          </p>
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;
